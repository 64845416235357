import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const BlockProjects = makeShortcode("BlockProjects");
const AnimateIntroRow = makeShortcode("AnimateIntroRow");
const Decoration = makeShortcode("Decoration");
const AnchorCTA = makeShortcode("AnchorCTA");
const BlockNews = makeShortcode("BlockNews");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo light" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Projects
    </Heading>
    <LearnMore elementId="projects_list" color="turq" mdxType="LearnMore" />
  </Block>
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Hausman's Integrated Operations & Maintenance Solutions have served a fast-growing list of clients and partners so far.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="projects_list" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <BlockProjects showFeaturedProject={true} mdxType="BlockProjects" />
    </Row>
    <AnimateIntroRow id="featured_service" className="featured" background={<><RowLines mdxType="RowLines" /><Decoration from="/projects/o-and-m-bg.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={3} className="intro_from_below" mdxType="Heading">O&M: Full-fledged Operations &<br /> Maintenance of Your Property</Heading>
  <AnchorCTA to="/services" mdxType="AnchorCTA">Our services</AnchorCTA>
  </Block>
  <Block className="content_block" mdxType="Block">
  <Paragraph mdxType="Paragraph">We make it a point to be proactive in identifying and preventing potential drawbacks to keep your business running smoothly, with a keen sense of safety, schedule, and cost-effectiveness.</Paragraph>
  </Block>
    </AnimateIntroRow>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />
    <Row id="news" background={true} data-background-animation="false" mdxType="Row">
  <BlockNews id="news_block" className="content_block" mdxType="BlockNews" />
    </Row>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      